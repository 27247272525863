import * as React from 'react';
import { useContext, useEffect } from 'react';
import { FieldsMappingContext } from '../../Context';
import { TableDataFields } from '../../../data-mapping';

export default function (props) {
  const {table} = useContext(FieldsMappingContext);
  const [data, setData] = table;

  useEffect(() => {
    if (data === undefined) {
      setData(TableDataFields)
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="label">Columns</div>
      <div className="form-element">
        <textarea value={data.columns ?? ''} onChange={e => setData({...data, columns: e.target.value})}></textarea>
        <p className="inline-hints">One item per line.</p>
      </div>

      <div className="label">Labels</div>
      <div className="form-element">
        <textarea value={data.labels ?? ''} onChange={e => setData({...data, labels: e.target.value})}></textarea>
        <p className="inline-hints">One item per line (order should match "Columns" values).</p>
      </div>
    </>
  );
}
