import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { mappingTypes } from '../../data-mapping';
import { FieldsMappingContext } from '../Context';
import { CollectionInfo } from './field-mappings/CollectionInfo';
import { CardMapper } from './field-mappings/CardMapper';
import { AccordionMapper } from './field-mappings/AccordionMapper';
import TableMapper from './field-mappings/TableMapper';

/**
 * Fields mapping form.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.type
 * @returns {JSX.Element}
 * @constructor
 */
const FieldsMapper = ({label, type}) => {
  const types = {
    [mappingTypes.card]: CardMapper,
    [mappingTypes.accordion]: AccordionMapper,
    [mappingTypes.table]: TableMapper,
  }

  let MapperForm = types[type] || <p>This feature is currently unavailable.</p>;

  return (
    <div className="fs-fields-mapper">
      <div className="fs-fields-mapper__label">{label}</div>
      <div className="fs-fields-mapper__form"><MapperForm/></div>
    </div>
  );
};

export const FacetedSearchFieldMappings = ({info}) => {
  const mappingDataStorage = useRef(document.getElementById('typesense_collection_field_mappings'));
  const {card, accordion, table} = useContext(FieldsMappingContext);
  const [cardData, setCardData] = card;
  const [accordionData, setAccordionData] = accordion;
  const [tableData, setTableData] = table;

  useEffect(() => {
    if (!mappingDataStorage.current.innerHTML) {
      return;
    }
    try {
      const defaultValue = JSON.parse(mappingDataStorage.current.innerHTML);
      setCardData(defaultValue.card);
      setAccordionData(defaultValue.accordion);
      setTableData(defaultValue.table);
    } catch (e) {
      setCardData({});
      setAccordionData({});
      setTableData({});
    }
  }, []);

  // Update field storage.
  useEffect(() => {
    mappingDataStorage.current.innerHTML = JSON.stringify({
      card: cardData,
      accordion: accordionData,
      table: tableData,
    })
  }, [cardData, accordionData, tableData]);

  return <div className="fs-admin-widget fs-admin-fields-mapping">
    <div className="columns">
      <div className="column">
        <h3 className="fs-admin-panel-title">Field mappings</h3>
        <CollectionInfo collection={info}/>
      </div>
      <div className="column scroll-x">
        <div className="fs-fields-mappers">
          <FieldsMapper label='Card' type={mappingTypes.card}/>
          <FieldsMapper label='Accordion' type={mappingTypes.accordion}/>
          <FieldsMapper label='Table' type={mappingTypes.table}/>
        </div>
      </div>
    </div>
  </div>
}
