import * as React from 'react';
import { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import Modal, { setConfirmText, setConfirmTextVisibility, setDismissText, setTitle, store } from './Modal';
import { addError } from '../packs/tracing';

export const confirmStepTextKey = isRDP => isRDP === "true" ? 'rdp_text' : 'text';

const REDIRECTION_TIMEOUT = 5;

async function leaveProgram(programID, setError) {
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': getCsrfToken(),
  };

  const response = await fetch(`/programs/${programID}/applications/me`, {
    method: 'DELETE',
    headers: headers,
  });

  if (!response.ok) {
    switch (response.status) {
      case 403:
        setError(I18n.t(`profiles.programs.modal.leave_program.error.forbidden`));
        break;
      default:
        setError(I18n.t(`profiles.programs.modal.leave_program.error.default`));
        break;
    }
    throw new Error();
  }

  return response.json();
}

export default function LeaveProgramModal(props) {
  return (
    <Provider store={store}>
      <LeaveProgramComponent {...props} />
    </Provider>
  )
}

const LeaveProgramComponent = (props) => {
  const dispatch = useDispatch();
  const {
    headingText,
    confirmationText,
    dismissText,
  } = props;

  const [text, setText] = useState('');
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(setTitle(headingText));
    dispatch(setConfirmText(confirmationText));
    dispatch(setDismissText(dismissText));
    dispatch(setConfirmTextVisibility(true));
  }, []);

  const onOpenCallback = (payload) => {
    setError('');
    setHasError(false);
    const {isRDP} = payload;
    setText(I18n.t(`profiles.programs.modal.leave_program.${confirmStepTextKey(isRDP)}`));
    dispatch(setTitle(headingText));
    dispatch(setConfirmTextVisibility(true));
  };

  const onClickCallback = (payload) => {
    const {isRDP, programName, programId} = payload;
    setText(I18n.t(`profiles.programs.modal.leave_program.confirm_step.${confirmStepTextKey(isRDP)}`, {program_name: programName}));

    dispatch(setTitle(I18n.t('profiles.programs.modal.leave_program.confirm_step.title')));
    dispatch(setDismissText(I18n.t('profiles.programs.modal.leave_program.close')));
    dispatch(setConfirmTextVisibility(false));

    leaveProgram(programId, setError)
      .then(response => {
        const programElement = document.getElementById(`user-profile-program-${programId}`);
        if (programElement) {
          programElement.remove();
        }

        const {redirect_to} = response;
        if (redirect_to) {
          setTimeout(() => window.location.href = redirect_to, REDIRECTION_TIMEOUT * 1000);
        }
      })
      .catch(error => {
        setHasError(true);
        addError('user-programs', error);
        dispatch(setTitle(I18n.t('profiles.programs.modal.leave_program.error.title')));
      });
  };
  return (
    <Modal onOpenCallback={onOpenCallback} onClickCallback={onClickCallback}>
      {hasError && <div>{error}</div>}
      {!hasError && <div dangerouslySetInnerHTML={{__html: text}}></div>}
    </Modal>
  );
};
